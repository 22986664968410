// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import React from 'react';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB9PG2Uuh2OP15g9G2paEbarlN3WXjqLBk",
  authDomain: "knowitall-60a83.firebaseapp.com" || "knowitrevise.com",
  projectId: "knowitall-60a83",
  storageBucket: "knowitall-60a83.appspot.com",
  messagingSenderId: "803069223202",
  appId: "1:803069223202:web:472911215519c2f1ed457b",
  measurementId: "G-RFEF964D86"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export default app;
