import { configureStore, createStore } from '@reduxjs/toolkit'
import questionSetReducer from './questionSlice'
import logger from 'redux-logger'
import userSetreducer from './userSlice'
import userFireSetReducer from './userFireSlice'
import autoSetReducer from './autoSlice'
//import timer from '../timer/reducers/timer'
import React from 'react';


export const store = configureStore({
    reducer: {
        questionSet: questionSetReducer,
        userSet: userSetreducer,
        userFireSet: userFireSetReducer,
        autoSet: autoSetReducer,
        //timer: timer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  })