import React from "react"; import {  questionFinal, SettingQuestions, settingQuestions,  } from "./arithmeticQuestions"; import { useDispatch, useSelector } from "react-redux"; 
 import { setsatsCss, setSatsScore, clearSatsAnswers,setMarking, holdTheQuestions, holdTheFractions, setSats } from "../redux/questionSlice";
import {addVariablePoints, setSatsComplete} from '../redux/userFireSlice'
import { doc , updateDoc} from "firebase/firestore"; import { db, auth } from "../firebase-config"; 
import { useNavigate } from "react-router-dom";import { ref, getDownloadURL } from "firebase/storage"; import { storage } from "../firebase-config"; 

let qA = []; let qAF = []; let qS //=[...questionFinal]; 
let qF //= [...fractions]
let startTime = new Date();  let timeTaken = 0; let t1 = new Date().getTime();
t1= new Date().getTime();   let b= 0 ; let buttones ="";
let imageLibrary=[]

function restartTime (){ t1 = new Date().getTime(); }
function timeDifference (){ let t2 = new Date().getTime();  let t3 = (t2-t1)/1000;  return t3}

export async function SaveSatsInFirestore(score, points){
    const updateRef = doc(db, 'users', auth.currentUser.uid)
    await updateDoc( updateRef, { sats: score, points: points  }, {merge:true});
     console.log("Document written with ID: ", auth.currentUser.uid);}
  
const Mark = ()=>{ 
  let answerStyle = 'answerWrong'
  let QuestionSetMarked =[]
  let fractionSetMarked = []
  const dispatch = useDispatch()
  const points = useSelector ((state) => state.userFireSet.points)
  let satsComplete = useSelector ((state) => state.userFireSet.SatsComplete)
  const mark = useSelector ((state) => state.questionSet.satsMarking)
  let satsComp = satsComplete 
  const choice  = useSelector((state) => state.questionSet.sats)
  const pre = new RegExp(/[£\$\s]+/)
  const post =new RegExp(/[$|£|%|f-z|F-Z|cm|,\s]+/)

  const handleMark = (e)=> { e.preventDefault(); ;
    let time = timeDifference()
    let minutes = 0
    while (time >= 60) {minutes++; time = time - 60}
    if (minutes > 0) {timeTaken = minutes + ' minutes, ' + time.toFixed(1) + ' seconds'}
    else {timeTaken =  time.toFixed(1) + ' seconds'}
   
    //console.log(startTime.getTime())

    for (let x = 0 ; x < qS.length ; x ++) { try{
      let index = qS.findIndex((e) => {if (e != undefined){ if (e.id === qA[x].id){return true} else {return false}} else {return false}})
        if (index != -1){
          let stringForRE = qA[x].yourAnswer
          let tempstring = stringForRE.replace(pre, '')
          let tempstring2 = tempstring.replace(post, '')
        //  console.log('1: '+ stringForRE + ', 2: ' + tempstring + ', 3: ' + tempstring2 + ' ans: ' + qS[index].answer)
          if (qS[index].answer == qA[x].yourAnswer || 
              qS[index].answer +'0' == qA[x].yourAnswer || 
              '0' + qS[index].answer == qA[x].yourAnswer || 
              qS[index].answer + '00' == qA[x].yourAnswer ||  qS[index].answer.toString() == tempstring2
              )
            {b = b + 1 ; answerStyle = 'answerRight'; // console.log('b: ' + b + ' q: ' +qS[index].answer + ' ' + qA[x].yourAnswer)
          }
       // else { answerStyle = 'answerWrong'} 
      }}  catch{} }

     for (let x = 0 ; x < qF.length ; x ++) { try{
      let index = qF.findIndex((e) => {if (e != undefined){ if (e.id === qAF[x].id){return true} else {return false}} else {return false}})
        if (index != -1){
            if (qF[index].answer == qAF[x].yourAnswer){b = b + 1 ; /*answerStyle = 'answerRight' */}
           // else { answerStyle = 'answerWrong'}
           }}  catch{} }        
    
    dispatch(setSatsScore(b))
    dispatch(setMarking(true))
    dispatch(addVariablePoints(points+b))
    let newSats = [{date: Date(), time: timeTaken, score: b, test: choice}]
    let satsComp2 = newSats.concat(satsComp)
    dispatch(setSatsComplete(satsComp2))
    SaveSatsInFirestore(satsComp2, points)
  }  // end of hanldeMark

  if(mark === true){ //
    QuestionSetMarked =  qS.map((q) =>{let aa = q.id ; //let imgSrc; if (q.img != undefined){ imgSrc = imageLibrary[aa]} else {imgSrc = undefined, console.log('no image')}
      let index = qA.findIndex((e) => {if (e != undefined){ if (e.id === q.id){return true} else {return false}} else {return false}})
        if (index === -1)
        {

          try {if (q.eng === 0){
            buttones = q.question.map((e)=>{return <p className='satsButton' value={e} key={e}>{e}</p>})
            return ( <li className={'listSatsLong'} key= {q.id} >{q.id} {q.instruct2} {buttones}  <p> Answer: {q.answer}  </p> <p> This was not answered</p>  </li> ) 
             }}
          catch (error){console.log(error + q)}

          try {if (q.eng === 1){
            return ( <li className={'listSatsLong'} key= {q.id} > {q.id}<p>{q.instruct2}</p> {q.question} <p> Answer: {q.answer}  </p> 
              <p> This was not answered</p>    </li> ) 
             }}
          catch (error){console.log(error)}

          try {if (q.eng === 2){
            return ( <li className={'listSatsLong'} key= {q.id} > {q.id}<p>{q.instruct2}</p> {q.question} <p> Answer: {q.answer}  </p> 
              <p> This was not answered</p>    </li> ) 
             }}
          catch (error){console.log(error)}

          try {if (q.eng === 3){ console.log('eng3' )
             return ( <li className={'listSatsLong'} key= {q.id} >{q.id},  <p className="satsP">{q.instruct2}</p> <p  className="satsP">{q.instruct1}  </p><br></br> <p> Answer: {q.answer}  </p> 
             <p> This was not answered</p>    </li> ) 
               }}
            catch (error){console.log(error)}


          try {if (q.question instanceof Array){
           // console.log('tally not')
            return ( 
              
              <li className={'listReasoningTally'} key= {aa} ><p className='satsQNumber'>{aa}: {q.instruct1}  <br></br> 
              <p className="tally">
                  {q.question[0]} <br></br> 
                  {q.question[1]} <br></br>
                  {q.question[2]} <br></br> 
                  {q.question[3]} <br></br>
                  {q.question[4]} <br></br>
                  {q.question[5]} <br></br>
                  {q.question[6]} <br></br>
                  {q.question[7]} <br></br>
                  {q.question[8]} <br></br>
                  {q.question[9]} <br></br>
                  {q.question[10]} <br></br>
              </p>
                  </p>
              <div className="instruct">{q.instruct2}</div> 
             <p> {q.workingOut}</p>  <div >Answer: {q.answer} </div>   <p> This was not answered</p> </li>
           )

           }} 
           catch (error) {console.log('error: ' + error)}

              let imageNum = "imgSat" + aa.toString()
              let imageHeight = 50
              if (q.image != ''){imageHeight = 200}
              try{
                if (q.image != ''){ let KnowItR = "images/" + q.image
                  let imageReference = ref(storage, KnowItR);
                    getDownloadURL(ref(storage, KnowItR))
                      .then((url) => {
                       const imgSat = document.getElementById('imgSat'+q.id.toString());
                        imgSat.setAttribute('src', url);
                        imageLibrary.push(url)
                       })
                      .catch((error) => { // Handle any errors 
                        });}
                  else {getDownloadURL(ref(storage, 'images/knowit.jpg'))
                    .then((url) => {
                     const img = document.getElementById('imgSat'+ q.id.toString());
                      img.setAttribute('src', url);
                      imageLibrary.push(url)
                    })}}
                catch {console.log('caught'); }

                return ( 
            <li className={'listReasoning'} key={q.id} ><p className='satsQNumber'>{q.id}:  {q.instruct1}  {q.question} <img className="imgSat"  height={imageHeight} id={imageNum} 
            src={imageLibrary[aa-1]} ></img> </p>  <div className="instruct">{q.instruct2}</div>  
            <p> {q.workingOut}</p>  <div >Answer: {q.answer} </div>   <p> This was not answered</p> </li>)
          /*}
                
          else {console.log('non reasining choice')
            return (
               <li className={'listSats'} key= {q.id} >  {q.question} 
                  <p> {q.workingOut}</p>
                  <div> Answer: {q.answer} </div> 
                  <p> This was not answered</p>
                </li>) 
              } */
        }
        else { let index1 = qA.findIndex((e) => {if (e != undefined){ if (e.id === q.id){return true} else {return false}} else {return false}})
          if (index1 != -1){//let imgSrc; if (q.img != undefined){ imgSrc = imageLibrary[aa]} else {imgSrc = undefined}
            let stringForRE = qA[index1].yourAnswer; let tempstring; let tempstring2; let buttones;
         try { tempstring = stringForRE.replace(pre, '')
          tempstring2 = tempstring.replace(post, '')}
          catch (error) {console.log(error)}
         // console.log('1: '+ stringForRE + ', 2: ' + tempstring + ', 3: ' + tempstring2 + ' ans: ' + q.answer)
            if (q.answer == qA[index1].yourAnswer || q.answer + '0' == qA[index1].yourAnswer || '0' + q.answer == qA[index1].yourAnswer || q.answer + '00' ==  qA[index1].yourAnswer || q.answer.toString() == tempstring2)
              { answerStyle = 'answerRight'; //console.log(q.id ,'right')
            }
            else {answerStyle = 'answerWrong'}

           console.log('q: ' + q.eng )
            try {if (q.eng === 0){
              buttones = q.question.map((e)=>{return <p className='satsButton'  value={e} key={e}>{e}</p>})
              return ( <li className={'listSatsLong'} key= {q.id} > {q.id}<p>{q.instruct2}</p> {buttones} <p  className="satsP">Answer: {q.answer}  </p> <div className={answerStyle}> Your answer: {qA[index].yourAnswer}</div>   </li> ) 
               }}
            catch (error){console.log(error)}

            try {if (q.eng === 1){
             return ( <li className={'listSatsLong'} key= {q.id} > {q.id}<p>{q.instruct2}</p> {q.question} <p  className="satsP"> Answer: {q.answer}  </p> <div className={answerStyle}> Your answer: {qA[index].yourAnswer}</div>   </li> ) 
               }}
            catch (error){console.log(error)}

            try {if (q.eng === 2){
             return ( <li className={'listSatsLong'} key= {q.id} > {q.id}<p>{q.instruct2}</p> {q.question} <p  className="satsP"> Answer: {q.answer}  </p> <div className={answerStyle}> Your answer: {qA[index].yourAnswer}</div>   </li> ) 
               }}
            catch (error){console.log(error)}

            try {if (q.eng === 3){ console.log('eng3' )
             return ( <li className={'listSatsLong'} key= {q.id} >{q.id},  <p className="satsP">{q.instruct2}</p> <p  className="satsP">{q.instruct1}  </p><br></br> <p  className="satsP"> Answer: {q.answer}  </p> <div className={answerStyle}> Your answer: {qA[index].yourAnswer}</div>  </li> ) 
               }}
            catch (error){console.log(error)}

            try {if (q.question  instanceof Array){
            //  console.log('tally answer')
              return ( 
                
                <li className={'listReasoningTally'} key= {aa} ><p className='satsQNumber'>{aa}: {q.instruct1}  <br></br> 
                <p className="tally">
                    {q.question[0]} <br></br> 
                    {q.question[1]} <br></br>
                    {q.question[2]} <br></br> 
                    {q.question[3]} <br></br>
                    {q.question[4]} <br></br>
                    {q.question[5]} <br></br>
                    {q.question[6]} <br></br>
                    {q.question[7]} <br></br>
                    {q.question[8]} <br></br>
                    {q.question[9]} <br></br>
                    {q.question[10]} <br></br>
                </p>
                    </p>
                <div className="instruct">{q.instruct2}</div> 
               <p> {q.workingOut}</p>  
              <p  className={answerStyle}> Answer: {q.answer}  </p> <div className={answerStyle}> Your answer: {qA[index].yourAnswer}</div>  </li> )
  
             }
                } catch (error) {console.log('error: ' + error)}
  




            
            let imageNum = "imgSat" + aa.toString()
            let imageHeight = 50
            if (q.image != ''){imageHeight = 200}
            try{
              if (q.image != ''){ let KnowItR = "images/" + q.image
                let imageReference = ref(storage, KnowItR);
                  getDownloadURL(ref(storage, KnowItR))
                    .then((url) => {
                     const imgSat = document.getElementById('imgSat'+q.id.toString());
                      imgSat.setAttribute('src', url);
                      imageLibrary.push(url)
                     
                    })
                    .catch((error) => { // Handle any errors 
                      });}
                else {getDownloadURL(ref(storage, 'images/knowit.jpg'))
                  .then((url) => {
                   const img = document.getElementById('imgSat'+ q.id.toString());
                    img.setAttribute('src', url);
        
                    imageLibrary.push(url)
                  })}}
              catch {console.log('caught'); }
              return ( 
              <li className={'listReasoning'} key= {q.id} ><p className='satsQNumber'>{q.id}:   {q.instruct1}  {q.question}   <img className="imgSat"  height={imageHeight} id={imageNum} 
              src={imageLibrary[aa-1]} ></img> </p>  
              <div className="instruct">{q.instruct2}</div> 
               <p> {q.workingOut}</p>  
              <p  className={answerStyle}> Answer: {q.answer}  </p> <div className={answerStyle}> Your answer: {qA[index].yourAnswer}</div>  </li>)
           /* }
            else {
              return (  <li className={'listSats answerStyle'} key= {q.id} >  {q.question}  <p> {q.workingOut}</p>  
              <p  className={answerStyle}>Answer: {q.answer} </p> <p className={answerStyle}> Your answer: {qA[index].yourAnswer}</p>  </li>) }
          }
          */
    }}} 
)              
                    
    fractionSetMarked = qF.map((q)=>{ 
      let index = qAF.findIndex((e) => {if (e != undefined){ if (e.id === q.id){return true} else { return false;}} else {return false}})
       if (index === -1){        
        return (
            <li className={'listSats'} key={q.id}>
              <div className="fractionSet">
                  <p className='integer'>{q.id+qS.length}:  {q.question.c}</p>
                  <div className='fraction'>
                  <p className='top'> {q.question.a}</p>
                  <p className='bottom'>{q.question.b}</p>  
              </div>
            <p className="integer"> {q.question.g} {q.question.f}</p> 
            <div className='fraction'>
              <p className='top'>{q.question.d}</p>
              <p className='bottom'>{q.question.e}</p> 
            </div></div>
            <p className='integer'> = {q.answer}</p>            
            <p> This was not answered</p>
            </li> )  }

        else { let index1 = qAF.findIndex((e) => {if (e != undefined){ if (e.id === q.id){return true} else {return false}} else {return false}})
        if (index1 != -1){ //console.log(index, qS.length, q.id)
            let answer = qAF[index1].yourAnswer
           // console.log('q.a: ' + q.answer + 'your: ' + answer )
            if (q.answer == answer){ answerStyle = 'answerRight'}   /////////// this may need other options for answers
            else {answerStyle = 'answerWrong'}
                return (
                    <li className={'listSats answerStyle'} key={q.id}>
                        <div className="fractionSet">
                            <p className='integer'>{q.id + qS.length}:  {q.question.c}</p>
                            <div className='fraction'>
                              <p className='top'> {q.question.a}</p>
                              <p className='bottom'>{q.question.b}</p>  
                            </div>
                            <p className="integer"> {q.question.g} {q.question.f}</p> 
                            <div className='fraction'>
                              <p className='top'>{q.question.d}</p>
                              <p className='bottom'>{q.question.e}</p> 
                            </div>
                            <div className={answerStyle}>
                              <p className='integer'> = {q.answer}</p>
                            </div> </div>
                            <p className={answerStyle}> Your answer: {answer}</p>
                            </li> )
            }   }})
                 
    return (
            <div>  <ul className='satsPaper'> 
            {QuestionSetMarked} 
            {fractionSetMarked} 
            </ul> {timeTaken}   </div>
                )
    }                           
                
else {  return ( <button className='satsButton'  onClick={handleMark} >Mark</button> )}
}

export const Arithmetic = ()=>{ let a =0
    const dispatch = useDispatch(); const navigate = useNavigate()
    
    let marking = useSelector((state) => state.questionSet.satsMarking)
    const choice  = useSelector((state) => state.questionSet.sats)
    const qS1 = useSelector((state) => state.questionSet.satsQuestions)
    const qF1 = useSelector((state) => state.questionSet.satsFractions)
   // console.log(qS1)
   //qS = settingQuestions(choice)
   qF = qF1//[...qF1]
   qS = qS1//[...qS1]
   //dispatch(holdTheQuestions(qS));
/*
   if (choice != 'Arithmetic Key Stage 2'|| 'Arithmetic Key Stage 1'||'Add fractions easy'|| 'Add fractions hard' || 
    'Minus fractions easy'|| 'Minus fractions hard' || 'Multiply fractions' || 'Divide fractions') {qF =[]}
   if (choice === 'Add fractions easy'|| 'Add fractions hard' ||  'Minus fractions easy'|| 'Minus fractions hard' || 
   'Multiply fractions' || 'Divide fractions') {qS= []; }
   */
//   console.log(qS)
const QuestionSet = qS.map((question)=>{ a++
    const handleChange=(e)=>{ 
           const match = (g) => g.id === question.id; //console.log(e, e.id)
           let index = qS.findIndex(match)  //// the index of the current question in qS
           qA[index] = {id: question.id, yourAnswer: e.target.value}
         // try {e.style.backgroundColor = 'lightblue'} 
       // catch {console.log('error')}
      }
// console.log('choice: ' + choice)

const handlePress=(e)=>{ 
  const match = (g) => g.id === question.id; console.log(e, e.id, question.id)
  let index = qS.findIndex(match)  //// the index of the current question in qS
  qA[index] = {id: question.id, yourAnswer: e.target.textContent}; console.log(qA[index])
 try {e.target.style.backgroundColor = 'lightblue'} 
catch {console.log('error')}}
   
try {if (question.eng === 0){
  console.log('eng0' )
  let buttones = question.question.map((e)=>{return <p className='satsButton' onClick={handlePress} value={e} key={e}>{e}</p>})
  return ( <li className={'listSatsLong'} key= {question.id} >{question.id},  <p className="satsP">{question.instruct2}</p> <p  className="satsP">{buttones}</p>      </li> ) 
   }}
catch (error){console.log(error)}

try {if (question.eng === 1){ console.log('eng1' )
let buttones = question.question.map((e)=>{return <p className='satsButton' onClick={handlePress} value={e} key={e}>{e}</p>})
  return ( <li className={'listSatsLong'} key= {question.id} >{question.id},  <p className="satsP">{question.instruct2}</p><br></br>  <p  className="satsP">{buttones}</p>   </li> ) 
   }}
catch (error){console.log(error)}

try {if (question.eng === 2){ console.log('eng2' )
let buttones = question.instruct1.map((e)=>{return <p className='satsButton' onClick={handlePress} value={e} key={e}>{e}</p>})
  return ( <li className={'listSatsLong'} key= {question.id} >{question.id},  <p  className="satsP">{question.instruct2} {question.question}</p> <br></br> <p  className="satsP">{buttones}</p>  </li> ) 
   }}
catch (error){console.log(error)}

try {if (question.eng === 3){ console.log('eng3' )
let buttones = question.question.map((e)=>{return <p className='satsButton' onClick={handlePress} value={e} key={e}>{e}</p>})
  return ( <li className={'listSatsLong'} key= {question.id} >{question.id},  <p className="satsP">{question.instruct2}</p> <p  className="satsP">{question.instruct1}  </p><br></br> <p  className="satsP">{buttones}</p>   </li> ) 
   }}
catch (error){console.log(error)}
        

           try {if (question.question  instanceof Array){
           // console.log('tally')
            return ( 
              <li className={'listReasoningTally'} key= {a} ><p className='satsQNumber'>{a}: {question.instruct1}  <br></br> 
              <p className="tally">
                  {question.question[0]} <br></br> 
                  {question.question[1]} <br></br>
                  {question.question[2]} <br></br> 
                  {question.question[3]} <br></br>
                  {question.question[4]} <br></br>
                  {question.question[5]} <br></br>
                  {question.question[6]} <br></br>
                  {question.question[7]} <br></br>
                  {question.question[8]} <br></br>
                  {question.question[9]} <br></br>
                  {question.question[10]} <br></br>
              </p>
                  </p>
              <div className="instruct">{question.instruct2}</div> <input className='inputAnswer' onChange={(e)=>{handleChange(e)}}/>  </li>)

           }
              } catch (error) {console.log('error: ' + error)}

           
           // console.log('KS2R')
    let imageNum = "imgSat" + a.toString()
    let imageHeight = 50
    if (question.image != ''){imageHeight = 200}
    try{
      if (question.image != ''){ let KnowItR = "images/" + question.image
      // console.log(a + ", " +question.id + ',  image: ' + KnowItR)
        let imageReference = ref(storage, KnowItR);
          getDownloadURL(ref(storage, KnowItR))
            .then((url) => {
             const imgSat = document.getElementById('imgSat'+question.id.toString());
              imgSat.setAttribute('src', url);
              imageLibrary.push(url)
             
            })
            .catch((error) => { // Handle any errors 
              });}
        else {getDownloadURL(ref(storage, 'images/knowit.jpg'))
          .then((url) => {
           const img = document.getElementById('imgSat'+ question.id.toString());
            img.setAttribute('src', url);

            imageLibrary.push(url)
          })}}
      catch {console.log('caught'); }
    //  console.log(imageLibrary)
      

    

      return ( 
      <li className={'listReasoning'} key= {a} ><p className='satsQNumber'>{a}: {question.instruct1}  {question.question} </p><img className="imgSat" height={imageHeight} id={imageNum} 
      src={imageLibrary[a-1]} 
      ></img> <div className="instruct">{question.instruct2}</div> 
          <input className='inputAnswer' onChange={(e)=>{handleChange(e)}}/>  </li>)
          
      /*   }
   
    else if (choice === 'Maths reasoning Key Stage 1' ||  'KS1missingNumber' || 'KS1missingNumber2'
       /// put the question list here with or spaces ||
          )  
      {  console.log('KS1R')
      try{
        if (question.image != ''){ let KnowItR = "images/" + question.image
          let imageReference = ref(storage, KnowItR);
            getDownloadURL(ref(storage, KnowItR))
              .then((url) => {
               const imgSat = document.getElementById('imgSat'+a.toString());
                imgSat.setAttribute('src', url);
                imgSat.setAttribute('height', 200)
              })
              .catch((error) => { // Handle any errors 
                });}
          else {getDownloadURL(ref(storage, 'images/knowit.jpg'))
            .then((url) => {
             const img = document.getElementById('imgSat'+ a.toString());
              img.setAttribute('src', url);
              img.setAttribute('height', 200)
            })}}
  
        catch {console.log('caught'); }
        //let imageNum = "imgSat" + a.toString()
      return ( 
        <li className={'listReasoning'} key= {a} ><p className='satsQNumber'>{a}:  {question.instruct1} {question.question} </p> <img id={"imgSat"+a.toString()} ></img><div className="instruct">{question.instruct2}</div> 
        <input className='inputAnswer' onChange={(e)=>{handleChange(e)}}/>  </li>)}
    

///// need to add the lists for English ?


    else { console.log('other')
    return ( 
    <li className={'listSats'} key= {a} ><p className='satsQNumber'>{a}:</p>  {question.question} 
        <input className='inputAnswer' onChange={(e)=>{handleChange(e)}}/>  </li>)}
*/

})

const FractionSet = qF.map((question)=>{  a++
    const handleChange1=(e)=>{ 
            const match2 = (f) => f.id === question.id;
            let index2 = qF.findIndex(match2); // console.log(index2,e, e.id)
            qAF[index2] = {id: question.id, yourAnswer: e.target.value}
    } // the index of qA is the id number for the orginal question
        
 return (
        <li className={'listSats'} key={question.id}>{a}:
            <div className="fractionSet">
                <p className='integer'>{question.question.c}</p>
                <div className='fraction'>
                  <p className='top'> {question.question.a}</p>
                  <p className='bottom'>{question.question.b}</p>  
                </div>
                <p className="integer"> {question.question.g} {question.question.f}</p> 
                <div className='fraction'>
                  <p className='top'>{question.question.d}</p>
                  <p className='bottom'>{question.question.e}</p>  
                   </div>
                  <p className='integer'>=</p>
              </div>
             <input className='inputAnswer' onChange={(e) =>{handleChange1(e)}  }/>
        </li> )}


)

const HandleReset = (e)=>{  e.preventDefault();
    
   //console.log('resest')
    restartTime()
    dispatch(setsatsCss('answerSats'))
    dispatch(setMarking(false))
    dispatch(clearSatsAnswers())
    dispatch(setSats(''))
    dispatch(holdTheQuestions([])); 
    dispatch(holdTheFractions([]))

    imageLibrary=[]
    qA=[]
    qS=[]
    qF=[]
    b=0
    dispatch(setSatsScore(b))
   navigate('/sats')
   window.scrollTo({top: 0, left: 0,});

}
    
if (marking === false){
    return (  <div> <p className="satsInstructions">Write Fractions as whole number (if any) followed by a space, then numerator slash (/) denomimator. e.g. 1 1/2 </p>
     <p className="satsInstructions"> </p> <SettingQuestions /><ul className='satsPaper'>   {QuestionSet}    {FractionSet}
        </ul>  <Mark /> <button className='satsButton' onClick={HandleReset} >Next Test</button>  </div>
    )}

if (marking === true){ return ( <div> <Mark /> <button className='satsButton' onClick={HandleReset} >Next Test</button> </div>  )}


}
