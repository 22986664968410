import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'; import {getDoc, doc} from 'firebase/firestore'; import { useDispatch, useSelector } from 'react-redux';
import { db, storage } from "../firebase-config"; import { getDownloadURL, ref } from 'firebase/storage';
import { auth } from '../firebase-config';
import React from 'react';

const userFireSetSlice = createSlice({
  name: 'userFireSet',
  initialState: {  
     user: null  ,
     uid: '',
     reviseList:  [],
     dateJoined: '',
     first: '',
     lastLogin: '',
     modulesCompleted: [],
     points: 0,
     testResults: [],
     loggedInWith: '',
     visibile: false,
     SatsComplete: [],

  },
     

  reducers: {  
    addLoginMethod: (state, action) => { state.loggedInWith = action.payload}, // options Google, email, anon
    addRevise: (state, action) => { state.reviseList = action.payload},
    addModule: (state, action) => {state.modulesCompleted = action.payload},
    addTest: (state, action) => {state.testResults = action.payload},
    addPoints: (state, action) => {if (state.visibile === true) { state.visibile = false ; } 
    else { state.visibile =true; state.points = action.payload +1} },
    addTwoPoints: (state, action) => { state.points = action.payload +2 },
    add10Points: (state, action) => { state.points = action.payload +10 },
    add100Points: (state, action) => { state.points = action.payload +100},
    addVariablePoints: (state, action) =>{state.points = action.payload}, // this requires the points to be added before the dispatch
    setSatsComplete: (state, action)=> {state.SatsComplete = action.payload},
    updateName: (state, action) => {state.first = action.payload},
    login : (state, action) => 
    {if(action.payload.displayName != null){state.user = action.payload.displayname} else state.user = action.payload.email; 
      state.uid = action.payload.uid}, 
    logoutFire: (state) => {state.user = null; state.uid = ''; state.reviseList = []; state.dateJoined = ''; 
    state.first = ''; state.SatsComplete= []; state.lastLogin = ''; state.modulesCompleted = ''; state.points = 0; state.testResults= []; state.loggedInWith='' ;
    }
  },
  extraReducers:   (builder) => { builder.addCase(fetchUserFromFirestore.fulfilled, (state, action)=>{
    let userD = action.payload.result; 
    console.log(userD)
    state.uid = userD.userId; state.reviseList = userD.revise; state.SatsComplete = userD.sats//chaged userD.uid to userD.userId
     state.dateJoined = userD.dateJoined; state.lastLogin = userD.lastLogin;
      state.modulesCompleted = userD.modulesCompleted
    state.points = userD.points; state.testResults = userD.testResults
    state.first = userD.first;
    }   )
    
   }}) 
    

export const fetchUserFromFirestore = createAsyncThunk( 'userFireSet/fetchData', 
          async (userRef, thunkAPI) => { const docRef = doc(db, 'users' , userRef); //userRef is the uid, which is also the name of the document in firestore
          const response = await getDoc(docRef); let result = {uid:"1"}
         // if (response.exists()) {
          console.log("Data:", response.data()); result = response.data() 
        //}
          
          return { result};  })

          
export const { login, logoutFire, addRevise, addLoginMethod, addModule, addPoints, addTwoPoints, add10Points, add100Points, addTest, 
  updateName, addVariablePoints, setSatsComplete } = userFireSetSlice.actions
            export default userFireSetSlice.reducer