import { useNavigate } from "react-router"; import { useSelector, useDispatch } from "react-redux";
import {Login} from './Login'
import React from 'react';

const Password = () => 
  {
    const navigate = useNavigate();
    const handlePassword = () => { navigate('/changePassword')}
    const handleDisplayName= () => { navigate('/changeName')} 
    const loggedInWith = useSelector((state) => state.userFireSet.loggedInWith )

    if (loggedInWith === 'email')
      {
        return ( 
        <div>
          <button onClick={handlePassword}>Change password</button>
          <button onClick={handleDisplayName}>Add display name</button> 
        </div>
        )
      }
  }

export const Account = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.userSet.user);
  const userDate = useSelector((state) =>state.userFireSet.dateJoined)
  const modulesComplete = useSelector((state) =>state.userFireSet.modulesCompleted)
  const reviseList = useSelector((state) =>state.userFireSet.reviseList)   // {reviseList}
  const satsComplete = useSelector((state) => state.userFireSet.SatsComplete)
  let name = 'Guest'; let startDate = 'today';  let email =  'Email not registered'

  if (user != null) { 
    startDate = userDate.substring(4,15); 
    if (user.displayName != undefined){name = user.displayName};
    if(user.email != undefined){email = user.email}  
    }
  if (user != null) {      
      let b=0; let satsCompleted
      if (satsComplete)
        {  
        satsCompleted = satsComplete.map((entry)=> { let date = entry.date.substring(4,15); b++; 
        return(<li className='satsList ' value ={entry.test} >{entry.test}, Score: {entry.score}, Time taken: {entry.time}, Date: {date}</li>) })
        }
      let c=0; let modulesCompleted
      if (modulesComplete)
        {  
        modulesCompleted = modulesComplete.map((entry)=> { let date = entry.date.substring(4,15);   c++; 
        return(<li className='satsList ' value ={entry.module} >{entry.module}, Date: {date}</li>) })
        }

      return (
      <div className="account">
        <div>
          User: {name}   Member since {startDate}<br/> 
          Modules completed: <ul>{modulesCompleted}</ul> <br/>
          Revision questions:  <br/>
          Email: {email}  <br/>
         completed: 
         <ul  >{satsCompleted}</ul>
        </div>
       <Password />
      </div>
      )
    }
  else { return <div> Please login first <Login /></div>}
};

/*
  //const handleEmail= ()=> { navigate('/changeEmail')}  
      //const handleAugmentLogin = ()=> { navigate('/changeLogin') }
      //const handleSubjects = () => {navigate('/changeSubjects')}     <button onClick={handleSubjects}>Change subjects</button>
<button onClick={handleEmail}>Change email</button>
     <button onClick={handleAugmentLogin}>Change anonymous login to Google or email login</button>  
     */
