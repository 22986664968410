import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'; import {getDoc, doc} from 'firebase/firestore'; import { useDispatch, useSelector } from 'react-redux';
import { db, storage } from "../firebase-config"; import { getDownloadURL, ref } from 'firebase/storage';
import { auth } from '../firebase-config';
import React from 'react';

const userSetSlice = createSlice({
  name: 'userSet',
  initialState: {  
     user: null  ,
     token: ''
  },
  reducers: {  
    login : (state, action) => {state.user = action.payload;}, 
    logout: (state) => {state.user = null;}
  },
  extraReducers:  (builder) => { builder.addCase( fetchToken.fulfilled, (state, action)=>{
        state.token = action.payload
      })} })

export const fetchToken = createAsyncThunk( 'user/fetchToken', 
        async ( thunkAPI) => { const isAuthenticated = await auth.currentUser.getIdToken(false); 
          return isAuthenticated})

          
export const { login, logout } = userSetSlice.actions
            export default userSetSlice.reducer