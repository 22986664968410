import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { onAuthStateChanged } from 'firebase/auth';
import {getDoc, doc} from 'firebase/firestore'
import { useDispatch, useSelector } from 'react-redux';
import { db } from "../firebase-config";
import React from 'react';

const questionSetSlice = createSlice({
  name: 'questionSet',
  initialState: { 
    master: [], 
    masterMeta:[], 
    masterHigh: "", 
    masterLow: "", 
    masterId: 0, 
    catalogueF: [],
    
    dataSet: [], 
    revise: [],
    value: 0, question: '',
    answer: '',
    givenAnswer:'', 
    description: '',
    primarySubject: '', 
    visibility: false,
    auto: false,  
    reviseButton: false, 
    start: true, 
    ends: false, 
    level: 0,
    meta : [],
    imageRef: '',

    revising: false,
    sats: '',
    satsScore: 0,
    satsCss: 'answerSats',
    satsAnswers: ['ignor'],
    theRightAnswers: ['ignore'],
    satsQuestions: [],
    satsFractions: [],
    satsMarking: false,
   
    time: 0
  },
  reducers: {
    setTime: (state, action) => {state.time = action.payload},
    
    setCatalogueF: (state, action) => {state.catalogueF = action.payload   },
    setLevel: (state, action) => {state.level = action.payload; if (state.level === 2){state.value = 0; state.start = true}},
    setMasterHigh: (state, action) => {state.masterHigh = action.payload;
    state.catalogueF = state.master.filter((entry) => {return entry.HighLevelSubject === action.payload})  },
    setMasterLow: (state, action) => {state.masterLow = action.payload},
    setMasterId: (state, action) => {state.masterId = action.payload.id; state.masterLow = action.payload.cat; state.value = 0; state.start = true},
    answerToggle: (state) => {state.visibility = false },
    nextQuestion: (state) => { if (state.visibility === true) { state.visibility = false ; } 
      else { state.visibility =true; 
        (state.value < (state.dataSet.length -1))? state.value += 1: state.value = 0}
        state.question = state.dataSet[state.value].question;
        state.answer =  state.dataSet[state.value].answer;
        state.description = state.dataSet[state.value].description;
        state.primarySubject = state.dataSet[state.value].primarySubject;  
      state.imageRef = state.dataSet[state.value].imageRef   },
     end: (state) => { state.ends = true},
     removeNonQuestions: (state, action) => {state.dataSet = action.payload},
    knowIt: (state, action) => { 
      if (state.visibility === true) { state.visibility = false ; } ////Added this so need to press knowit twoce
      else {
      if (state.revising === true){ state.revise = action.payload}
      if (action.payload.length < 1) {state.ends = true; state.revising = false}
      else {
      state.dataSet = action.payload;
     // (state.dataSet.length < 1) ? state.end = true : state.end = false;
      if (state.value < (state.dataSet.length -1)){state.value = state.value} else { state.value = 0};  //// have taken the += state.value out 
      state.visibility = true 
      //console.log( state.value, state.dataSet, state.dataSet[state.value])
      state.question = state.dataSet[state.value].question;
      state.answer =  state.dataSet[state.value].answer;
        state.description = state.dataSet[state.value].description;
        state.primarySubject = state.dataSet[state.value].primarySubject;
        state.imageRef = state.dataSet[state.value].imageRef  }}
        
      
    },
    revise: (state, action) => { 
      if (state.visibility === true) { state.visibility = false ; } ////Added this so need to press knowit twoce
      else {
      state.revise = action.payload;
      (state.value < (state.dataSet.length -1))? state.value += 1: state.value = 0;
      state.visibility = true 
      state.question = state.dataSet[state.value].question;
        state.answer =  state.dataSet[state.value].answer;
        state.description = state.dataSet[state.value].description;
        state.primarySubject = state.dataSet[state.value].primarySubject;
        state.imageRef = state.dataSet[state.value].imageRef  }
    },
    revision: (state, action) => {
      state.dataSet = action.payload;
      state.revising = true;
      state.ends = false;  state.value = 0; 
      state.visibility = false; state.auto = false;
      state.question = state.dataSet[0].question;
      state.answer =  state.dataSet[0].answer;
      state.description = state.dataSet[0].description;
      state.primarySubject = state.dataSet[0].primarySubject;    
    },
   // autoChange: (state) => { if (state.auto === true){ state.auto = false} else {state.auto = true}  },
    Switch: (state) => {},
    Start: (state) => {state.value = 0; state.start = true},
    increment: (state) => {    state.value += 1   },
    decrement: (state) => {    state.value -= 1   },
    setSats: (state, action) => { state.sats = action.payload},
    setSatsScore: (state, action) => {state.satsScore = action.payload},
    setsatsCss: (state, action) => {state.satsCss = action.payload} ,
    setAnswer: (state,action)=> {state.satsQuestions[action.payload[0]]= action.payload[1]},
    clearSatsAnswers: (state) => {state.satsAnswers = ['ignor']},
    setTheRightAnswers: (state, action) => {state.theRightAnswers[action.payload[0]]= action.payload[1]},
    holdTheQuestions: (state, action) => {state.satsQuestions = action.payload},
    holdTheFractions: (state, action) => {state.satsFractions = action.payload},
    setMarking: (state,action) => {state.satsMarking = action.payload}
  },
  extraReducers: 
  
    (builder) => { builder.addCase(fetchDataFromFirestore.fulfilled, (state, action)=>{
     let arrayData = action.payload.data; // data is an array
     let metaData = action.payload.meta; ;  // meta is an object
     //console.log(metaData.title) 
      if(metaData.title === 'Master' ) { state.masterMeta = action.payload.meta; state.master = arrayData}
     else {  state.dataSet = arrayData;  state.start =false;   state.ends = false; state.meta = action.payload.meta
     state.question = state.dataSet[state.value].question;   state.answer =  state.dataSet[state.value].answer; state.revising = false;
     state.description = state.dataSet[state.value].description; state.primarySubject = state.dataSet[state.value].primarySubject; 
     state.SatsComplete = state.dataSet[state.value].sats;
     state.visibility = true;      state.value = 0}   })},   
 } )

export const { increment, end, setMasterId, decrement, setCatalogueF, setMasterHigh, start, setLevel, setMasterLow, 
  removeNonQuestions, incrementByAmount, answerToggle, nextQuestion, nextCategory, previousCategory, knowIt, revise, setTime,
  revision, Switch, Start, setSats, setSatsScore, setsatsCss, setAnswer, setTheRightAnswers,setArithmetic, clearSatsAnswers,
  setMarking, holdTheQuestions,holdTheFractions, setSatsComplete } = questionSetSlice.actions
export default questionSetSlice.reducer

export const fetchDataFromFirestore = createAsyncThunk( 'questionSet/fetchData', 
    async (coll, thunkAPI) => { const docRef = doc(db, ('Master/dataSet/' + coll.collection) , coll.document);
      console.log(coll.collection)
    const response = await getDoc(docRef);
    if (response.exists()) {//console.log("Data:", response.data());
  }
    let initialisedSet = Object.values(response.data().data.body)
    let meta = response.data().data.meta
    //console.log( 'meta: ' + meta.title + 'data' + initialisedSet)
    return {meta: meta, data: initialisedSet};  })


 
/*
export const autoPlayInterval = createAsyncThunk('questionSet/autoPlayInterval',

function Move() {
  const dispatch = useDispatch();
  let count = useSelector((state) => state.questionSet.value)
  let id
  if(count)
    {
  id =  setInterval(dispatch(nextQuestion()), 2000);
    }
  else {clearInterval(id); console.log()} 
                  } ;
                  Move()
                  )
*/
