import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useUserAuth } from "../context/userAuthContext";
import db from '../firebase-config'
import { Form } from "react-bootstrap"; 
import { setDoc, doc, collection,addDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react"; import { useSelector, useDispatch } from 'react-redux'
import { decrement, increment, answerToggle, removeNonQuestions, nextQuestion, end, knowIt, revise, revision, autoChange, Switch, Start, fetchDataFromFirestore, setMasterId, setLevel } from '../redux/questionSlice'
import { logout } from "../redux/userSlice"; import { auth } from "../firebase-config"; import { logoutFire } from "../redux/userFireSlice";


const Home = () => {useDispatch(setLevel(1))
    const dispatch = useDispatch();   const navigate = useNavigate();
    const logInType = useSelector((state)=> state.userFireSet.loggedInWith)
  
    const handleLogout = () => {
      if (logInType != 'anon'){ dispatch(logoutFire());}
       // console.log('anon')
       // dispatch to the store with the logout action   /// swapped these
       // sign out function from firebase
      dispatch(logout())
      auth.signOut();
      navigate("/");
    };
    const handleHome = () => {navigate("/")}

    return (
      <div>
        <button className="navi"  onClick={handleLogout}>
          Change Log In
        </button>
      </div>
      );
};

export default Home;
