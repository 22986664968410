import React from 'react';

export const Privacy = ( ) => {

    return (



        <div className="privacy" >

<h2>Know It All Ltd Privacy Policy </h2>

<div>
<br/>
This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from knowItRevise.com, know-it-all.co.uk or knowitall-60a83.web.app (the “Site”).

We receive, collect and store any information you enter on our website or provide us in any other way. In addition, we collect the Internet protocol (IP) address used to connect your computer to the Internet; login; e-mail address; password; device and connection information and purchase history. We may use software tools to measure and collect session information, including page response times, length of visits to certain pages, page interaction information, and methods used to browse away from the page. We also collect personally identifiable information (including name, email, password, communications);If you provide them we will collect payment details (including credit card information), comments, feedback, product reviews, recommendations, and personal profile.

When you conduct a transaction on our website, as part of the process, we collect personal information you give us such as your name, address and email address. Your personal information will be used for the specific reasons stated above only.


</div><div>
Under the UK General Data Protection Regulation (UK GDPR), the lawful bases we rely on for processing this information are: 
(a) Your consent. You are able to remove your consent at any time. You can do this by contacting us, details below. 
<br/>
Your information is securely stored. We keep your email and usage history for as long as you use the services provided. We will then dispose your information by deleting accounts that have been dormant for over a year.
<br/>
We collect such Non-personal and Personal Information for the following purposes:
<ul><li>
To provide and operate the Services;
</li><li>
To provide our Users with ongoing customer assistance and technical support;
</li><li>
To be able to contact our Visitors and Users with general or personalized service-related notices and promotional messages;
</li><li>
To create aggregated statistical data and other aggregated and/or inferred Non-personal Information, which we or our business partners may use to provide and improve our respective services;
</li><li>
To comply with any applicable laws and regulations.
</li></ul>
</div>
<div>
<br/>
Our company is hosted on Google firebase platform. Google provides us with the online platform that allows us to provide and sell our products and services to you. Your data may be stored through Google's data storage, analytics, databases and the general Google.com applications.
</div><div>
<br/>
We may contact you to notify you regarding your account, to troubleshoot problems with your account, to resolve a dispute, to collect fees or monies owed, to poll your opinions through surveys or questionnaires, to send updates about our company, or as otherwise necessary to contact you to enforce our User Agreement, applicable national laws, and any agreement we may have with you. For these purposes we may contact you via email, telephone, text messages, and postal mail.
<br/><br/>
We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.
<br/>
</div><div>
<br/>
We also use Google Analytics to help us understand how our customers use the Site--you can read more about how Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.
<br/>
</div><div>

<br/>
Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.
<br/>
</div><div>
<br/>
BEHAVIOURAL ADVERTISING
<br/>
As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. For more information about how targeted advertising works, you can visit the Network Advertising Initiative's (“NAI”) educational page at http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
<br/>
</div><div>
<br/>
You can opt out of targeted advertising by:

   GOOGLE - https://www.google.com/settings/ads/anonymous

   <br/>
Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance's opt-out portal at: http://optout.aboutads.info/.
<br/>
DO NOT TRACK

Please note that we do not alter our Site's data collection and use practices when we see a Do Not Track signal from your browser.
<br/>
</div><div>
<br/>
YOUR RIGHTS
<br/>
If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.
<br/>
Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to the UK, Canada and the United States.
<br/>

Under data protection law, you have rights including:
<ul><li>
Your right of access - You have the right to ask us for copies of your personal information. 
</li><li>
Your right to rectification - You have the right to ask us to rectify personal information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete. 
</li><li>
Your right to erasure - You have the right to ask us to erase your personal information in certain circumstances. 
</li><li>
Your right to restriction of processing - You have the right to ask us to restrict the processing of your personal information in certain circumstances. 
</li><li>
Your right to object to processing - You have the the right to object to the processing of your personal information in certain circumstances.
</li><li>
Your right to data portability - You have the right to ask that we transfer the personal information you gave us to another organisation, or to you, in certain circumstances.
</li><li>
You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond to you.
</li></ul>
Please contact us at the email address below if you wish to make a request.
<br/>
How to complain
<brr/>
If you have any concerns about our use of your personal information, you can make a complaint to us at the email address below.
You can also complain to the ICO if you are unhappy with how we have used your data.
<br/>
The ICO's address:  
<br/>          
Information Commissioner's Office
<br/>
Wycliffe House
<br/>Water Lane
<br/>Wilmslow
<br/>Cheshire
<br/>SK9 5AF
<br/>
<br/>Helpline number: 0303 123 1113
<br/>ICO website: https://www.ico.org.uk
<br/>
</div><div>
<br/>
DATA RETENTION
<br/>
When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information. We may retain your information for longer if we have a legitimate interest to do so.
<br/>
</div><div>
<br/>
If you would like to: access, correct, amend or delete any personal information we have about you, or if you don't want us to process your data anymore, please contact us at knowitalluk1@gmail.com or send us mail to: 66 Lutterworth Road, CV23 0QF, UK.
<br/>
        </div></div>
    )
}